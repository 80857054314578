import * as React from "react"
import '../css/main.css'
import '../css/bootstrap.min.css'
import '../css/animate.compat.css'
import '../css/boxicons.min.css'
import '../css/icofont.min.css'
import '../css/owl.carousel.min.css'

import Head from '../components/head'
import HeaderComponent from "../components/header.js"
import useScript from "../components/useScript"
import FooterComponent from "../components/footer"

// markup
const ContactPage = () => {
  
    useScript('static/js/jquery.min.js')
    useScript('static/js/bootstrap.bundle.js')
    useScript('static/js/jquery.easing.min.js')
    useScript('static/js/venobox.min.js')
    useScript('static/js/jquery.waypoints.min.js')
    useScript('static/js/counterup.min.js')
    useScript('static/js/owl.carousel.min.js')
    useScript('static/js/isotope.pkgd.min.js')
    useScript('static/js/aos.js')
    useScript('static/js/main.js')

  return (
    <>
      <Head title={'Contact us'} />
      <header id="header" className="fixed-top">
        <HeaderComponent />
      </header>
      <main id='main'>
        <section className="breadcrumbs">
            <div className="container">
                <div className="d-flex justify-content-between align-items-center">
                <h2>Contact</h2>
                <ol>
                    <li><a href="/">Home</a></li>
                    <li>Contact</li>
                </ol>
                </div>
            </div>
        </section>
        <section className="contact" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="info-box">
                                <i className="bx bx-map"></i>
                                <h3>Our Address</h3>
                                <p>Latvia, Riga, Brivibas 144</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                    <div className="info-box">
                        <i className="bx bx-envelope"></i>
                        <h3>Email Us</h3>
                        <p>hello@kvotum.com | support@kvotum.com</p>
                        </div>                        
                    </div>
                </div>
            </div>
        </section>
        <section className="map mt-2">
            <div className="container-fluid p-0">
                <iframe title="Map" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5862.985057193455!2d24.136675785461634!3d56.96427096084265!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46eece4adf42d34b%3A0x855918ef1cb8ef5!2zQnLEq3bEq2JhcyBpZWxhIDE0NCwgTGF0Z2FsZXMgcHJpZWvFoXBpbHPEk3RhLCBSxKtnYSwgTFYtMTAxMg!5e0!3m2!1sru!2slv!4v1598359010043!5m2!1sru!2slv" frameborder="0" style={{border: 0}} allowfullscreen=""></iframe>
            </div>
        </section>
      </main>
      <footer id="footer" data-aos="fade-up" data-aos-easing="ease-in-out" data-aos-duration="500">
        <FooterComponent />
      </footer>
    </>
  )
}

export default ContactPage